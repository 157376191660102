import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const IndexPage = () => (
  <Layout>
    <div className={'vw-container'}>
      <div className={'vw-item'}>
        <h1>A K R A S I A</h1>
        <div class={'social-bar'}>
          <i>Coming soon...</i>
          <FontAwesomeIcon icon={'coffee'}></FontAwesomeIcon>
        </div>
        <br/><br/>
        <div class={'social-bar'}>
          <a href={'https://www.twitch.tv/akrasiaband'} style={{color: 'white'}}>
            <FontAwesomeIcon className={'social-item'} icon={['fab', 'twitch']}></FontAwesomeIcon>
          </a>
          <a href={'https://twitter.com/akrasia_metal'} style={{color: 'white'}}>
            <FontAwesomeIcon className={'social-item'} icon={['fab', 'twitter']}></FontAwesomeIcon>
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
